import LazyIframe from "components/common/iframe/lazyIframe";
import LayoutStep from "components/common/layout/layoutStep";
// import Button from "components/ui/atoms/button/button";
import { titleTrainingDays } from "data/config";
import Link from "gatsby-link";
import React from "react";

import SEO from "../../components/SEO/SEO";

const Step9 = () => {
  return (
    <LayoutStep>
      <SEO title={`CIFPA | ${titleTrainingDays}`} description={"CIFPA"} canonical={"training-days/step-9/"} />
      <div className="container step">
        <p className={"h1"}>{titleTrainingDays} - Step 9</p>
        <h1>
          {" "}
          <span className={"uppercase"}>CIFPA</span>
        </h1>
        <section className="videoCart">
          <main className={"slideLeft s05"}>
            <div className={"video--bloque--a"}></div>
            <LazyIframe src={"8lIAmlMeL4c"} title={"Life in the company"} />
          </main>
          <aside className={"slideLeft s1"}>
            <div className="stepNavigation">
              <Link to="/training-days/step-8/#top" className={"stepNavigation__button--back "}>
                {" "}
                <span className={"stepNavigation__button--back--right"}> </span> Back{" "}
              </Link>
              <Link to="/training-days/step-10/#top" className={"stepNavigation__button--next"}>
                {" "}
                <span className={"stepNavigation__button--next--left"}> </span> Next{" "}
              </Link>
            </div>
            <p>In this video you can watch the facilities, equipment and services of the VET Innovation Centre.</p>
          </aside>
        </section>
      </div>
    </LayoutStep>
  );
};

export default Step9;
